import { createReducer, on } from '@ngrx/store';
import { login, loginFailure, loginSuccess, logout, register, registerFailure, registerSuccess } from './authentication.actions';
import { UserObj } from 'src/services/AuthService';

export interface AuthState {
  user: UserObj | null;
  isLoading: boolean;
  error: Error | null;
}

const initialState: AuthState = {
  user: null,
  isLoading: false,
  error: null,
};

export const authenticationReducer = createReducer(
  initialState,

  on(register, (state) => ({ ...state, isLoading: true, error: null })),
  on(registerSuccess, (state, { user }) => ({ ...state, isLoading: false, user, error: null, })),
  on(registerFailure, (state, { error }) => ({ ...state, error, isLoading: false })),

  on(login, (state) => ({ ...state, error: null, isLoading: true })),
  on(loginSuccess, (state, { user }) => ({ ...state, isLoading: false, user, error: null, })),
  on(loginFailure, (state, { error }) => ({ ...state, error, isLoading: false })),

  on(logout, (state) => ({ ...state, user: null })),
);
