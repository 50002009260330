export interface MenuItem {
  label: string;
  icon?: string;
  link?: string;
  subItems: MenuItem[];
  isTitle: boolean;
  badge?: any;
}


const menu = (builder: () => MenuItemBuilder[]): MenuItem[] => builder().map(item => item.build());

const item = (label: string): MenuItemBuilder => new MenuItemBuilder(label);

class MenuItemBuilder {
  private item: MenuItem;

  constructor(label: string) {
    this.item = {
      label,
      subItems: [],
      isTitle: false,
    };
  }

  icon(icon: string): MenuItemBuilder {
    this.item.icon = icon;

    return this;
  }

  route(link: string): MenuItemBuilder {
    this.item.link = link;

    return this;
  }

  sub(subItems: () => MenuItemBuilder[]): MenuItemBuilder {
    this.item.subItems = subItems().map(sub => sub.build());

    return this;
  }

  title(): MenuItemBuilder {
    this.item.isTitle = true;

    return this;
  }

  build(): MenuItem {
    return this.item;
  }
}

export const MENU = menu(() => [
  item('HOOFDMENU').title(),

  item('Dashboard').icon('ph-gauge').sub(() => [
    item('Submenu 1').route('/'),
    item('Submenu 2').route('/'),
  ]),

  item('BEHEER').title(),

  item('Gebruikers').icon('ph-users-three').route('/administration/users'),
]);
