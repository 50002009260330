import { createAction, props } from '@ngrx/store';
import { UserObj } from 'src/services/AuthService';

// New user registration
export const register = createAction(
  '[Auth] Register',
  props<{ name: string, companyName: string, email: string, password: string }>(),
);

export const registerSuccess = createAction(
  '[Auth] Register success',
  props<{ user: UserObj }>(),
);

export const registerFailure = createAction(
  '[Auth] Register failure',
  props<{ error: Error }>(),
);

// User login
export const login = createAction(
  '[Auth] Login',
  props<{ email: string, password: string, remember: boolean }>(),
);

export const loginSuccess = createAction(
  '[Auth] Login success',
  props<{ user: UserObj }>(),
);

export const loginFailure = createAction(
  '[Auth] Login failure',
  props<{ error: Error }>(),
);

// logout action
export const logout = createAction(
  '[Auth] Logout',
);

export const logoutSuccess = createAction(
  '[Auth] Logout success',
);


