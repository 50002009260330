<app-breadcrumbs title="List View" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row align-items-center g-2">
                    <div class="col-lg-3 me-auto">
                        <h6 class="card-title mb-0">Users</h6>
                    </div>
                    <div class="col-lg-2">
                        <div class="search-box">
                            <input [formControl]="search" type="text" class="form-control search" placeholder="Search...">
                            <span class="ri-search-line search-icon"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive table-card">
                    <table class="table table-centered align-middle table-custom-effect table-nowrap mb-0">
                        <thead class="text-muted">
                            <tr>
                                <th>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="option" id="checkAll" [(ngModel)]="isAllSelected" (change)="onSelectAllToggle()">
                                        <label class="form-check-label" for="checkAll"></label>
                                    </div>
                                </th>
                                <th scope="col" class="sort cursor-pointer" (click)="sortBy('name')">Name</th>
                                <th scope="col" class="sort cursor-pointer" (click)="sortBy('email')">Email address</th>
                                <th scope="col" class="sort cursor-pointer" (click)="sortBy('status')">Email verified</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody class="list form-check-all">
                            <tr *ngFor="let user of users$ | async" id="user__{{user.id}}">
                                <td>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" [(ngModel)]="selectedUsers[user.id]">
                                        <label class="form-check-label"></label>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex gap-2 align-items-center">
                                        <div class="flex-grow-1">
                                            {{ user.name }}
                                        </div>
                                    </div>
                                </td>
                                <td>{{ user.email }}</td>
                                <td class="status">
                                  <span *ngIf="user.email_verified_at !== null" class="badge bg-info-subtle text-info">Email verified</span>
                                  <span *ngIf="user.email_verified_at === null" class="badge bg-danger-subtle text-danger">Email not verified</span>
                                </td>
                                <td>
                                    <ul class="d-flex gap-2 list-unstyled mb-0">
                                        <li>
                                            <button class="btn btn-subtle-primary btn-icon btn-sm "><i class="ph-eye"></i></button>
                                        </li>
                                        <li>
                                            <button class="btn btn-subtle-secondary btn-icon btn-sm edit-item-btn"><i class="ph-pencil"></i></button>
                                        </li>
                                        <li>
                                            <button class="btn btn-subtle-danger btn-icon btn-sm remove-item-btn"><i class="ph-trash"></i></button>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                      <div class="noresult" [ngClass]="{ 'd-none': ((allUsers$ | async)?.length || 0) > 0 && ((users$ | async)?.length || 0) > 0 }">
                        <div class="text-center py-4">
                            <i class="ph-magnifying-glass fs-1 text-primary"></i>
                            <h5 class="mt-2">Sorry! No Result Found</h5>
                            <p class="text-muted mb-0">We did not find any results for you search "{{ search.value }}".</p>
                        </div>
                    </div>
                </div>
                <div id="elmLoader">
                    <div [ngClass]="{ 'd-none': !(isLoading$ | async) }" class="spinner-border text-primary avatar-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div class="row align-items-center mt-4 pt-2" id="pagination-element">
                    <div class="col-sm">
                        <div class="text-muted text-center text-sm-start">
                            Showing <span class="fw-semibold">{{ (users$ | async)?.length }}</span> of <span class="fw-semibold">{{ (allUsers$ | async)?.length }}</span> Results
                        </div>
                    </div>
                    <div class="col-sm-auto mt-3 mt-sm-0">
                        <pagination class="pagination-wrap hstack gap-2 justify-content-center" [totalItems]="(allUsers$ | async)?.length || 0" [itemsPerPage]="10" [ngModel]="page$ | async" (ngModelChange)="page$.next($event)" [maxSize]="5"></pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
