import { createReducer, on } from '@ngrx/store';
import { User } from './users.models';
import { addUser, addUserFailed, addUserSuccessful, deleteUser, deleteUserFailure, deleteUserSuccess, fetchUsers, fetchUsersFailed, fetchUsersSuccessful, updateUser, updateUserFailed, updateUserSuccessful } from './users.actions';

export const usersFeatureKey = 'Administration.Users';

export interface UsersState {
  users: User[];
  isLoading: boolean;
  error: Error | null;
}

const initialState: UsersState = {
  users: <User[]>[],
  isLoading: false,
  error: null,
};

export const usersReducer = createReducer(
  initialState,

  on(fetchUsers, (state) => ({ ...state, isLoading: true, error: null })),
  on(fetchUsersSuccessful, (state, { users }) => ({ ...state, isLoading: false, users, error: null, })),
  on(fetchUsersFailed, (state, { error }) => ({ ...state, error, isLoading: false })),


  on(addUser, (state) => ({ ...state, isLoading: true, error: null })),
  on(addUserSuccessful, (state, { user }) => ({ ...state, isLoading: false, users: [...state.users, user], error: null, })),
  on(addUserFailed, (state, { error }) => ({ ...state, error, isLoading: false })),


  on(updateUser, (state) => ({ ...state, isLoading: true, error: null })),
  on(updateUserSuccessful, (state, { user }) => ({ ...state, isLoading: false, users: state.users.map(u => u.id === user.id ? user : u), error: null, })),
  on(updateUserFailed, (state, { error }) => ({ ...state, error, isLoading: false })),

  on(deleteUser, (state) => ({ ...state, isLoading: true, error: null })),
  on(deleteUserSuccess, (state, { user }) => ({ ...state, isLoading: false, users: state.users.filter(u => u.id !== user.id), error: null, })),
  on(deleteUserFailure, (state, { error }) => ({ ...state, error, isLoading: false })),
);
