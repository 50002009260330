import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { catchError, map, Observable, of, tap } from "rxjs";
import { login, loginFailure, loginSuccess, logoutSuccess, register, registerFailure, registerSuccess } from "src/app/store/Authentication/authentication.actions";

export type UserObj = {
  id: Number;
  name: string;
  email: string;
  email_verified_at: string;
  created_at: string;
  updated_at: string;
}

@Injectable({ providedIn: 'root' })
export default class AuthService {

  protected authenticatedUser: UserObj | null = null;

  constructor(protected http: HttpClient, protected store: Store) { }

  login(email: string, password: string, remember: boolean): Observable<UserObj> {
    this.store.dispatch(login({ email, password, remember }));

    return this.http.post<{ success: boolean, user: UserObj | null }>(
      '/auth/login',
      JSON.stringify({ email, password, remember }),
    ).pipe(
      map(response => {
        if (!response.success) {
          this.store.dispatch(loginFailure({ error: new Error('Login failed') }));

          throw new Error('Login failed');
        }

        this.store.dispatch(loginSuccess({ user: response.user! }));
        this.authenticatedUser = response.user!;

        return response.user!;
      }));
  }

  logout(): Observable<void> {
    return this.http.get<void>(
      '/auth/logout',
    ).pipe(
      tap(() => this.authenticatedUser = null),
      tap(() => this.store.dispatch(logoutSuccess())),
    );
  }

  isLoggedIn(forceCheck = false): Observable<boolean> {
    if (forceCheck || !this.authenticatedUser) {
      return this.user().pipe(map(user => !!user));
    }

    return of(!!this.authenticatedUser);
  }

  user(force = false): Observable<UserObj> {
    if (force || !this.authenticatedUser) {
      return this.http.get<{ user: UserObj | null }>(
        '/auth/user',
      ).pipe(
        tap(response => this.authenticatedUser = response.user),
        map(response => response.user!),
      );
    }

    return of(this.authenticatedUser);
  }

  register(name: string, companyName: string, email: string, password: string): Observable<UserObj> {
    this.store.dispatch(register({ name, email, companyName, password }));

    return this.http.post<{ success: boolean, user: UserObj | null }>(
      '/auth/register',
      JSON.stringify({ name, company_name: companyName, email, password }),
    ).pipe(
      map(response => {
        if (!response.success) {
          this.store.dispatch(registerFailure({ error: new Error('Register failed') }));

          throw new Error('Register failed');
        }

        this.store.dispatch(registerSuccess({ user: response.user! }));
        this.authenticatedUser = response.user;

        return response.user!;
      }));
  }
}
