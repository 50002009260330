<div class="app-menu navbar-menu">
  <div class="navbar-brand-box">
    <a routerLink="/" class="logo logo-dark">
      <span class="logo-sm">
        <img src="assets/images/logo-sm.png" alt="" height="22" />
      </span>
      <span class="logo-lg">
        <img src="assets/images/logo-dark.png" alt="" height="22" />
      </span>
    </a>
    <a routerLink="/" class="logo logo-light">
      <span class="logo-sm">
        <img src="assets/images/logo-sm.png" alt="" height="22" />
      </span>
      <span class="logo-lg">
        <img src="assets/images/logo-light.png" alt="" height="22" />
      </span>
    </a>
    <button
      type="button"
      class="btn btn-sm p-0 fs-3xl header-item float-end btn-vertical-sm-hover"
      id="vertical-hover"
      (click)="toggleMobileMenu($event)"
    >
      <i class="ri-record-circle-line"></i>
    </button>
  </div>

  <ngx-simplebar
    id="scrollbar"
    style="height: 100%"
    data-simplebar-track="light"
    class="h-100 simplebar-scrollable-y"
  >
    <div class="container-fluid">
      <div id="two-column-menu"></div>
      <ul class="navbar-nav" id="navbar-nav">
        <li
          *ngFor="let item of menuItems"
          [class]="item.isTitle ? 'menu-title' : 'nav-item'"
        >
          <ng-container *ngIf="item.isTitle; else defaultMenu">
            <span>{{ item.label }}</span>
          </ng-container>

          <ng-template #defaultMenu>
            <ng-container *ngIf="hasItems(item); else linkBlock">
              <a
                href="javascript:void(0);"
                class="nav-link menu-link"
                data-bs-toggle="collapse"
                [ngClass]="{
                  'has-arrow': !item.badge,
                  'has-dropdown': item.badge
                }"
                aria-expanded="false"
                (click)="toggleItem($event)"
              >
                <i class="{{ item.icon }}"></i>
                <span> {{ item.label }}</span>
                <span *ngIf="item.badge" class="badge badge-pill bg-danger">{{
                  item.badge
                }}</span>
              </a>
              <div class="collapse menu-dropdown mega-dropdown-menu">
                <ul class="nav nav-sm flex-column" aria-expanded="false">
                  <li *ngFor="let subitem of item.subItems" class="nav-item">
                    <ng-container
                      *ngIf="hasItems(subitem); else subitemLinkBlock"
                    >
                      <a
                        class="nav-link"
                        href="javascript:void(0);"
                        [attr.data-parent]="subitem.parentId"
                        data-bs-toggle="collapse"
                        aria-expanded="false"
                        (click)="toggleSubItem($event)"
                      >
                        {{ subitem.label }}
                      </a>
                      <!-- Nested sub-items, similar structure can be nested further as needed -->
                    </ng-container>
                    <ng-template #subitemLinkBlock>
                      <a
                        [routerLink]="subitem.link"
                        class="nav-link"
                        [attr.data-parent]="subitem.parentId"
                        (click)="updateActive($event)"
                      >
                        {{ subitem.label }}
                      </a>
                    </ng-template>
                  </li>
                </ul>
              </div>
            </ng-container>
            <ng-template #linkBlock>
              <a
                [routerLink]="item.link"
                class="side-nav-link-ref nav-link menu-link"
                (click)="toggleParentItem($event)"
              >
                <i
                  *ngIf="item.icon"
                  class="bx {{ item.icon }} icon nav-icon"
                ></i>
                <span> {{ item.label }} </span>
                <span *ngIf="item.badge" class="badge badge-pill bg-secondary">
                  {{ item.badge }}
                </span>
              </a>
            </ng-template>
          </ng-template>
        </li>
      </ul>
    </div>
  </ngx-simplebar>

  <div class="sidebar-background"></div>
</div>
<div class="vertical-overlay" (click)="SidebarHide()"></div>
