import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { fetchUsers } from 'src/app/store/administration/Users/users.actions';
import { BehaviorSubject, Observable, combineLatest, first, map, of, startWith } from 'rxjs';
import { User } from 'src/app/store/administration/Users/users.models';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { RootReducerState } from 'src/app/store';
import { usersFeatureKey } from 'src/app/store/administration/Users/users.reducer';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent {

  breadCrumbItems = [
    { label: 'Administration' },
    { label: 'Users', active: true }
  ];

  selectedUsers: { [userID: number]: boolean } = {};

  isAllSelected = false;

  users$: Observable<User[]> = of([]);

  allUsers$: Observable<User[]> = of([]);

  isLoading$ = this.store.select(state => state[usersFeatureKey].isLoading);

  search = new FormControl('');

  page$ = new BehaviorSubject(1);

  constructor(private store: Store<RootReducerState>) { }

  ngOnInit(): void {
    this.store.dispatch(fetchUsers());

    this.users$ = combineLatest([
      this.store.select(state => state[usersFeatureKey].users),
      this.search.valueChanges.pipe(startWith('')),
      this.page$,
    ]).pipe(
      map(([users, searchTerm, page]) => users.filter(
        user => user.name.toLowerCase().includes(searchTerm!.toLowerCase())
          || user.email.toLowerCase().includes(searchTerm!.toLowerCase())
      ).slice(page * 10 - 10, page * 10))
    );

    this.allUsers$ = this.store.select(state => state[usersFeatureKey].users);
  }

  sortBy(field: string) {
    throw new Error("Not implemented");
  }

  pageChanged(event: PageChangedEvent) {
    throw new Error("Not implemented");
  }

  onSelectAllToggle() {
    this.users$.pipe(first()).subscribe(users => {
      this.selectedUsers = {};

      users.forEach(user => this.selectedUsers[user.id] = this.isAllSelected);
    });
  }
}
