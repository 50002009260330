import { createAction, props } from "@ngrx/store";
import { User } from "./users.models";

export const fetchUsers = createAction('[Administration.Users] Fetch users');
export const fetchUsersSuccessful = createAction('[Administration.Users] Fetch users successful', props<{ users: User[] }>());
export const fetchUsersFailed = createAction('[Administration.Users] Fetch users failed', props<{ error: Error }>());


export const addUser = createAction('[Administration.Users] Add user', props<{ newUser: User }>());
export const addUserSuccessful = createAction('[Administration.Users] Add user successful', props<{ user: User }>());
export const addUserFailed = createAction('[Administration.Users] Add user failed', props<{ error: Error }>());


export const updateUser = createAction('[Administration.Users] Update user', props<{ modifiedUser: User }>());
export const updateUserSuccessful = createAction('[Administration.Users] Update user successful', props<{ user: User }>());
export const updateUserFailed = createAction('[Administration.Users] Update user failed', props<{ error: Error }>());


export const deleteUser = createAction('[Administration.Users] Delete user', props<{ userToDelete: User }>());
export const deleteUserSuccess = createAction('[Administration.Users] Delete user successful', props<{ user: User }>());
export const deleteUserFailure = createAction('[Administration.Users] Delete user failed', props<{ error: Error }>());
