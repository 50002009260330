import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as usersReducer from '../store/administration/Users/users.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UsersEffects as UsersEffects } from '../store/administration/Users/users.effects';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { UsersListComponent } from './users-list/users-list.component';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  { path: 'users', component: UsersListComponent },
];

@NgModule({
  declarations: [
    UsersListComponent
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(usersReducer.usersFeatureKey, usersReducer.usersReducer),
    EffectsModule.forFeature(UsersEffects),
    RouterModule.forChild(routes),
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),
  ],
  exports: [
    RouterModule,
  ],
})
export class AdministrationModule { }
