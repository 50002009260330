import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class AddBaseUrl implements HttpInterceptor {

  readonly baseUrl = 'https://api.test.kernenergiewetdossier.nl/api/';

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(
      request.clone({ url: `${this.baseUrl}${request.url.replace(/^\/+/g, '')}` })
    );
  }
}

@Injectable()
export class SetJsonHeaders implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const clone = request.clone({
      withCredentials: true,
      headers: request.headers.set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
    });

    return next.handle(clone);
  }
}
