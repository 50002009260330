<header id="page-topbar" (window:scroll)="windowScroll()">
    <div class="layout-width">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box horizontal-logo">
                    <a routerLink="/" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo-dark.png" alt="" height="22">
                        </span>
                    </a>

                    <a routerLink="/" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo-light.png" alt="" height="22">
                        </span>
                    </a>
                </div>

                <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none" id="topnav-hamburger-icon" (click)="toggleMobileMenu($event)">
                    <span class="hamburger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </button>

                <form class="app-search d-none d-md-inline-flex">
                    <div class="position-relative">
                        <input type="text" class="form-control" placeholder="Search..." autocomplete="off" id="search-options" value="" (keyup)="Search()">
                        <span class="mdi mdi-magnify search-widget-icon"></span>
                        <span class="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options" (click)="closeBtn()"></span>
                    </div>
                    <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
                        <ngx-simplebar style="max-height: 320px;">
                            <!-- item-->
                            <div class="dropdown-header">
                                <h6 class="text-overflow text-muted mb-0 text-uppercase">Recent Searches</h6>
                            </div>

                            <div class="dropdown-item bg-transparent text-wrap">
                                <a routerLink="/" class="btn btn-subtle-secondary btn-sm btn-rounded me-1">how to setup <i class="mdi mdi-magnify"></i></a>
                                <a routerLink="/" class="btn btn-subtle-secondary btn-sm btn-rounded">buttons <i class="mdi mdi-magnify"></i></a>
                            </div>
                            <!-- item-->
                            <div class="dropdown-header mt-2">
                                <h6 class="text-overflow text-muted mb-1 text-uppercase">Pages</h6>
                            </div>

                            <!-- item-->
                            <a href="javascript:void(0);" class="dropdown-item notify-item">
                                <i class="ri-bubble-chart-line align-middle fs-18 text-muted me-2"></i>
                                <span>Analytics Dashboard</span>
                            </a>

                            <!-- item-->
                            <a href="javascript:void(0);" class="dropdown-item notify-item">
                                <i class="ri-lifebuoy-line align-middle fs-18 text-muted me-2"></i>
                                <span>Help Center</span>
                            </a>

                            <!-- item-->
                            <a href="javascript:void(0);" class="dropdown-item notify-item">
                                <i class="ri-user-settings-line align-middle fs-18 text-muted me-2"></i>
                                <span>My account settings</span>
                            </a>

                            <!-- item-->
                            <div class="dropdown-header mt-2">
                                <h6 class="text-overflow text-muted mb-2 text-uppercase">Members</h6>
                            </div>

                            <div class="notification-list">
                                <!-- item -->
                                <a href="javascript:void(0);" class="dropdown-item notify-item py-2">
                                    <div class="d-flex">
                                        <img src="assets/images/users/48/avatar-2.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                                        <div class="flex-1">
                                            <h6 class="m-0">Angela Bernier</h6>
                                            <span class="fs-11 mb-0 text-muted">Manager</span>
                                        </div>
                                    </div>
                                </a>
                                <!-- item -->
                                <a href="javascript:void(0);" class="dropdown-item notify-item py-2">
                                    <div class="d-flex">
                                        <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                                        <div class="flex-1">
                                            <h6 class="m-0">David Grasso</h6>
                                            <span class="fs-11 mb-0 text-muted">Web Designer</span>
                                        </div>
                                    </div>
                                </a>
                                <!-- item -->
                                <a href="javascript:void(0);" class="dropdown-item notify-item py-2">
                                    <div class="d-flex">
                                        <img src="assets/images/users/avatar-5.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                                        <div class="flex-1">
                                            <h6 class="m-0">Mike Bunch</h6>
                                            <span class="fs-11 mb-0 text-muted">React Developer</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </ngx-simplebar>

                        <div class="text-center pt-3 pb-1">
                            <a href="javascript:void(0);" class="btn btn-primary btn-sm">View All Results <i class="ri-arrow-right-line ms-1"></i></a>
                        </div>
                    </div>
                </form>
            </div>

            <div class="d-flex align-items-center">

                <div dropdown class="dropdown topbar-head-dropdown ms-1 header-item">
                    <button dropdownToggle type="button" class="btn btn-icon btn-topbar btn-ghost-dark rounded-circle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class='bi bi-grid fs-2xl'></i>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 dropdown-responsive">
                        <div class="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h6 class="m-0 fw-semibold fs-base"> Browse by Apps </h6>
                                </div>
                                <div class="col-auto">
                                    <a href="javascript:void(0);" class="btn btn-sm btn-subtle-info"> View All Apps
                                        <i class="ri-arrow-right-s-line align-middle"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="p-2">
                            <div class="row g-0">
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/github.png" alt="Github">
                                        <span>GitHub</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/bitbucket.png" alt="bitbucket">
                                        <span>Bitbucket</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/dribbble.png" alt="dribbble">
                                        <span>Dribbble</span>
                                    </a>
                                </div>
                            </div>

                            <div class="row g-0">
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/dropbox.png" alt="dropbox">
                                        <span>Dropbox</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp">
                                        <span>Mail Chimp</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/slack.png" alt="slack">
                                        <span>Slack</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div dropdown class="dropdown ms-1 topbar-head-dropdown header-item">
                    <button dropdownToggle type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                        @if (flagvalue == undefined) {
                        <img id="header-lang-img" src="{{valueset}}" alt="Header Language" height="20" class="rounded">
                        }@else {
                        <img id="header-lang-img" src="{{flagvalue}}" alt="Header Language" height="20" class="rounded">
                        }
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-end">

                        <!-- item-->
                        @for (item of listLang; track $index) {
                        <a href="javascript:void(0);" class="dropdown-item notify-item language py-2" (click)="setLanguage(item.text, item.lang, item.flag)">
                            <img src="{{item.flag}}" alt="user-image" class="me-2 rounded" height="18">
                            <span class="align-middle">{{item.text}}</span>
                        </a>
                    }
                    </div>
                </div>

                <div dropdown #dropdown="bs-dropdown" [insideClick]="true" class="dropdown topbar-head-dropdown ms-1 header-item">
                    <button dropdownToggle type="button" class="btn btn-icon btn-topbar btn-ghost-dark rounded-circle" id="page-header-cart-dropdown">
                        <i class='bi bi-bag fs-2xl'></i>
                        <span class="position-absolute topbar-badge cartitem-badge fs-3xs translate-middle badge rounded-pill bg-info">{{cartData?.length}}</span>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-xl dropdown-menu-end p-0 product-list" aria-labelledby="page-header-cart-dropdown">
                        <div class="p-3 border-bottom">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h6 class="m-0 fs-lg fw-semibold"> My Cart <span class="badge bg-secondary fs-sm cartitem-badge ms-1">{{cartData?.length}}</span></h6>
                                </div>
                                <div class="col-auto">
                                    <a href="javascript:void(0);">View All</a>
                                </div>
                            </div>
                        </div>
                        <ngx-simplebar style="max-height: 300px;">
                            <div class="p-3">
                                @if (cartData?.length == 0) {
                                <div class="text-center empty-cart" id="empty-cart">
                                    <div class="avatar-md mx-auto my-3">
                                        <div class="avatar-title bg-info-subtle text-info fs-2 rounded-circle">
                                            <i class='bx bx-cart'></i>
                                        </div>
                                    </div>
                                    <h5 class="mb-3">Your Cart is Empty!</h5>
                                    <a href="javascript:void(0);" class="btn btn-success w-md mb-3">Shop Now</a>
                                </div>
                                }
                                @for (data of cartData; track $index) {
                                <div class="d-block dropdown-item product text-wrap p-2">
                                    <div class="d-flex">
                                        <div class="avatar-sm me-3 flex-shrink-0">
                                            <div class="avatar-title bg-light rounded">
                                                <img src="{{data.img}}" class="avatar-xs" alt="user-pic">
                                            </div>
                                        </div>
                                        <div class="flex-grow-1">
                                            <p class="mb-1 fs-sm text-muted">{{data.category}}</p>
                                            <h6 class="mt-0 mb-3 fs-md">
                                                <a href="javascript:void(0);" class="text-reset">{{data.title}}</a>
                                            </h6>
                                            <div class="text-muted fw-medium d-none">$<span class="product-price">{{data.price}}</span></div>
                                            <div class="input-step">
                                                <button type="button" class="minus" (click)="increment(data.qty,$index,'0')">–</button>
                                                <input type="number" class="product-quantity" [(ngModel)]="data.qty" min="0" max="100" readonly>
                                                <button type="button" class="plus" (click)="increment(data.qty,$index,'1')">+</button>
                                            </div>
                                        </div>
                                        <div class="ps-2 d-flex flex-column justify-content-between align-items-end">
                                            <button type="button" class="btn btn-icon btn-sm btn-ghost-primary remove-cart-btn" (click)="removeCart($index)"><i class="ri-close-fill fs-lg"></i></button>
                                            <h5 class="mb-0">$ <span class="product-line-price">{{data.total}}</span></h5>
                                        </div>
                                    </div>
                                </div>
                            }

                                @if (cartData?.length != 0) {
                                <div id="count-table">
                                    <table class="table table-borderless mb-0  fw-semibold">
                                        <tbody>
                                            <tr>
                                                <td>Sub Total :</td>
                                                <td class="text-end cart-subtotal">${{subtotal}}</td>
                                            </tr>
                                            <tr>
                                                <td>Discount <span class="text-muted">(Steex15)</span>:</td>
                                                <td class="text-end cart-discount">- ${{discount}}</td>
                                            </tr>
                                            <tr>
                                                <td>Shipping Charge :</td>
                                                <td class="text-end cart-shipping">${{shippingRate}}</td>
                                            </tr>
                                            <tr>
                                                <td>Estimated Tax (12.5%) : </td>
                                                <td class="text-end cart-tax">${{tax}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                }
                            </div>
                        </ngx-simplebar>
                        @if (cartData?.length != 0) {
                        <div class="p-3 border-bottom-0 border-start-0 border-end-0 border-dashed border" id="checkout-elem">
                            <div class="d-flex justify-content-between align-items-center pb-3">
                                <h5 class="m-0 text-muted">Total:</h5>
                                <div class="px-2">
                                    <h5 class="m-0 cart-total">${{totalsum}}</h5>
                                </div>
                            </div>

                            <a routerLink="/" class="btn btn-info text-center w-100" (click)="dropdown.hide()">
                                Checkout
                            </a>
                        </div>
                        }
                    </div>
                </div>

                <div class="ms-1 header-item d-none d-sm-flex">
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-dark rounded-circle" data-toggle="fullscreen" (click)="fullscreen()">
                        <i class="bi bi-arrows-fullscreen fs-lg"></i>
                    </button>
                </div>

                <div dropdown container="body" class="dropdown topbar-head-dropdown ms-1 header-item">
                    <button dropdownToggle type="button" class="btn btn-icon btn-topbar btn-ghost-dark rounded-circle mode-layout">
                        <i class="bi bi-sun align-middle fs-3xl"></i>
                    </button>
                    <div *dropdownMenu class="dropdown-menu p-2 dropdown-menu-end" id="light-dark-mode">
                        <a href="javascript:void(0);" class="dropdown-item" (click)="changeMode('light')"><i class="bi bi-sun align-middle me-2"></i>Licht</a>
                        <a href="javascript:void(0);" class="dropdown-item" (click)="changeMode('dark')"><i class="bi bi-moon align-middle me-2"></i>Donker</a>
                        <a href="javascript:void(0);" class="dropdown-item" (click)="changeMode('auto')"><i class="bi bi-moon-stars align-middle me-2"></i>Automatisch</a>
                    </div>
                </div>

                <div dropdown [insideClick]="true" class="dropdown topbar-head-dropdown ms-1 header-item" id="notificationDropdown">
                    <button dropdownToggle type="button" class="btn btn-icon btn-topbar btn-ghost-dark rounded-circle" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                        <i class='bi bi-bell fs-2xl'></i>
                        <span class="position-absolute topbar-badge fs-3xs translate-middle badge rounded-pill bg-danger"><span class="notification-badge">{{totalNotify}}</span><span class="visually-hidden">unread messages</span></span>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">

                        <div class="dropdown-head rounded-top">
                            <div class="p-3 border-bottom border-bottom-dashed">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6 class="mb-0 fs-lg fw-semibold"> Notifications <span class="badge bg-danger-subtle text-danger fs-sm notification-badge"> {{totalNotify}}</span></h6>
                                        <p class="fs-md text-muted mt-1 mb-0">You have <span class="fw-semibold notification-unread">{{newNotify}}</span> unread messages</p>
                                    </div>
                                    <div dropdown class="col-auto dropdown">
                                        <a dropdownToggle href="javascript:void(0);" data-bs-toggle="dropdown" class="link-secondary fs-md"><i class="bi bi-three-dots-vertical"></i></a>
                                        <ul *dropdownMenu class="dropdown-menu">
                                            <li><a class="dropdown-item" href="javascript:void(0);">All Clear</a></li>
                                            <li><a class="dropdown-item" href="javascript:void(0);">Mark all as read</a></li>
                                            <li><a class="dropdown-item" href="javascript:void(0);">Archive All</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="py-2 ps-2" id="notificationItemsTabContent">
                            <ngx-simplebar data-simplebar-track="light" style="max-height: 300px;" class="pe-2">
                                @for (data of notificationList; track $index) {
                                <div>
                                    @if (data.items.length > 0) {
                                    <h6 class="text-overflow text-muted fs-sm my-2 text-uppercase notification-title">{{data.title}}</h6>
                                    }
                                    @for (item of data.items; track $index) {
                                    <div class="text-reset notification-item d-block dropdown-item position-relative" [ngClass]="data.title == 'New'?'unread-message':''">
                                        <div class="d-flex">
                                            @if (item.avatar) {
                                            <div class="position-relative me-3 flex-shrink-0">
                                                <img src="{{item.avatar}}" class="rounded-circle avatar-xs" alt="user-pic">
                                                <span class="active-badge position-absolute start-100 translate-middle p-1 bg-success rounded-circle">
                                                    <span class="visually-hidden">{{item.activeBadge}}</span>
                                                </span>
                                            </div>
                                            }@else {
                                            <div class="avatar-xs me-3 flex-shrink-0">
                                                <span class="avatar-title {{item.background}} rounded-circle fs-lg">
                                                    <i class="{{item.icon}}"></i>
                                                </span>
                                            </div>
                                            }
                                            <div class="flex-grow-1">
                                                <a href="javascript:void(0);" class="stretched-link">
                                                    @if (item.name) {
                                                    <a href="javascript:void(0);" class="stretched-link">
                                                        <h6 class="mt-0 mb-1 fs-md fw-semibold">{{item.name}}</h6>
                                                    </a>
                                                    <div class="fs-sm text-muted">
                                                        <p class="mb-1">{{item.text}}</p>
                                                    </div>
                                                    }@else{
                                                    <h6 class="mt-0 fs-md mb-2 lh-base">
                                                        {{item.text}}
                                                    </h6>
                                                    }
                                                </a>

                                                <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                    <span><i class="mdi mdi-clock-outline"></i> {{item.timestamp}}</span>
                                                </p>
                                            </div>
                                            <div class="px-2 fs-base">
                                                <div class="form-check notification-check">
                                                    <input class="form-check-input" type="checkbox" value="{{item.id}}" [(ngModel)]="item.state" (change)="onCheckboxChange($event,$index)" id="{{item.checkboxId}}">
                                                    <label class="form-check-label" for="{{item.checkboxId}}"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                </div>
                            }
                            </ngx-simplebar>
                            <div class="notification-actions" id="notification-actions">
                                <div class="d-flex text-muted justify-content-center align-items-center">
                                    Select <div id="select-content" class="text-body fw-semibold px-1">{{checkedValGet.length}}</div> Result <button type="button" class="btn btn-link link-danger p-0 ms-2" (click)="removeNotificationModal.show()">Remove</button>
                                </div>
                            </div>
                            <div class="empty-notification-elem text-center px-4 d-none">
                                <div class="mt-3 avatar-md mx-auto">
                                    <div class="avatar-title bg-info-subtle text-info fs-24 rounded-circle">
                                        <i class="bi bi-bell "></i>
                                    </div>
                                </div>
                                <div class="pb-3 mt-2">
                                    <h6 class="fs-lg fw-semibold lh-base">Hey! You have no any notifications </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div dropdown class="dropdown ms-sm-3 header-item topbar-user">
                    <button dropdownToggle type="button" class="btn shadow-none" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="d-flex align-items-center">
                            <img class="rounded-circle header-profile-user" src="assets/images/users/32/avatar-1.jpg" alt="Header Avatar">
                            <span class="text-start ms-xl-2">
                                <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{ (userData$ | async)?.name }}</span>
                                <span class="d-none d-xl-block ms-1 fs-sm user-name-sub-text">Founder</span>
                            </span>
                        </span>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-end">
                        <!-- item-->
                        <h6 class="dropdown-header">Welcome Richard!</h6>
                        <a class="dropdown-item" routerLink="/"><i class="mdi mdi-account-circle text-muted fs-lg align-middle me-1"></i> <span class="align-middle"> Profile</span></a>
                        <a class="dropdown-item" routerLink="/"><i class="mdi mdi-message-text-outline text-muted fs-lg align-middle me-1"></i> <span class="align-middle"> Messages</span></a>
                        <a class="dropdown-item" routerLink="/"><i class="mdi mdi-calendar-check-outline text-muted fs-lg align-middle me-1"></i> <span class="align-middle"> Taskboard</span></a>
                        <a class="dropdown-item" routerLink="/"><i class="mdi mdi-lifebuoy text-muted fs-lg align-middle me-1"></i> <span class="align-middle"> Help</span></a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" routerLink=""><i class="mdi mdi-wallet text-muted fs-lg align-middle me-1"></i> <span class="align-middle"> Balance : <b>$8451.36</b></span></a>
                        <a class="dropdown-item" routerLink="/"><span class="badge bg-success-subtle text-success mt-1 float-end">New</span><i class="mdi mdi-cog-outline text-muted fs-lg align-middle me-1"></i> <span class="align-middle"> Settings</span></a>
                        <a class="dropdown-item" routerLink="/auth/lockscreen"><i class="mdi mdi-lock text-muted fs-lg align-middle me-1"></i> <span class="align-middle"> Lock screen</span></a>
                        <a class="dropdown-item" href="javascript: void(0);" (click)="logout()"><i class="mdi mdi-logout text-muted fs-lg align-middle me-1"></i> <span class="align-middle" data-key="t-logout"> Logout</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<!-- removeNotificationModal -->
<div bsModal #removeNotificationModal="bs-modal" id="removeNotificationModal" class="modal fade zoomIn" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" (click)="removeNotificationModal.hide()"></button>
            </div>
            <div class="modal-body p-md-5">
                <div class="text-center">
                    <div class="text-danger">
                        <i class="bi bi-trash display-4"></i>
                    </div>
                    <div class="mt-4 fs-base">
                        <h4 class="mb-1">Are you sure ?</h4>
                        <p class="text-muted mx-4 mb-0">Are you sure you want to remove this Notification ?</p>
                    </div>
                </div>
                <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button type="button" class="btn w-sm btn-light" (click)="removeNotificationModal.hide()">Close</button>
                    <button type="button" class="btn w-sm btn-danger" id="delete-notification" (click)="notificationDelete()">Yes, Delete It!</button>
                </div>
            </div>

        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<!-- removeCartModal -->
<div bsModal #removeCartModal="bs-modal" id="removeCartModal" class="modal fade zoomIn" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-cartmodal"></button>
            </div>
            <div class="modal-body p-md-5">
                <div class="text-center">
                    <div class="text-danger">
                        <i class="bi bi-trash display-5"></i>
                    </div>
                    <div class="mt-4">
                        <h4>Are you sure ?</h4>
                        <p class="text-muted mx-4 mb-0">Are you sure you want to remove this product ?</p>
                    </div>
                </div>
                <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button type="button" class="btn w-sm btn-light" (click)="removeCartModal.hide()">Close</button>
                    <button type="button" class="btn w-sm btn-danger" id="remove-cartproduct" (click)="confirmDelete()">Yes, Delete It!</button>
                </div>
            </div>

        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
