import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { map, Observable } from "rxjs";
import AuthService from "./services/AuthService";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(protected auth: AuthService, protected router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.auth.isLoggedIn().pipe(
      map(isLoggedIn => isLoggedIn || this.router.parseUrl('/auth/login'))
    );
  }

}

@Injectable({ providedIn: 'root' })
export class GuestGuard implements CanActivate {

  constructor(protected auth: AuthService, protected router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.auth.isLoggedIn().pipe(map(isLoggedIn => {
      if (!isLoggedIn) {
        console.log('You are not logged in, so we can continue as guest');

        return true;
      }

      console.log('We are re-routing to the home page, since you are logged in.');

      return this.router.parseUrl('/');
    }));
  }

}
