import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { addUser, addUserFailed, addUserSuccessful, deleteUser, deleteUserFailure, deleteUserSuccess, fetchUsers, fetchUsersFailed, fetchUsersSuccessful, updateUser, updateUserFailed, updateUserSuccessful } from './users.actions';
import { HttpClient } from '@angular/common/http';
import { User } from './users.models';

@Injectable()
export class UsersEffects {

  fetchUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUsers),
      exhaustMap(() =>
        this.http.get<{ users: User[] }>('/administration/users').pipe(
          map(response => fetchUsersSuccessful({ users: response.users })),
          catchError((error) => of(fetchUsersFailed({ error })))
        )
      )
    )
  );

  addUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addUser),
      exhaustMap(({ newUser }) =>
        this.http.post<{ user: User }>('/administration/users', newUser).pipe(
          map(response => addUserSuccessful({ user: response.user })),
          catchError((error) => of(addUserFailed({ error })))
        )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUser),
      exhaustMap(({ modifiedUser }) =>
        this.http.patch<{ user: User }>(`/administration/users/${modifiedUser.id}`, modifiedUser).pipe(
          map(response => updateUserSuccessful({ user: response.user })),
          catchError((error) => of(updateUserFailed({ error })))
        )
      )
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUser),
      exhaustMap(({ userToDelete }) =>
        this.http.delete<{ user: User }>(`/administration/users/${userToDelete.id}`).pipe(
          map(response => deleteUserSuccess({ user: response.user })),
          catchError((error) => of(deleteUserFailure({ error })))
        )
      )
    )
  );

  constructor(
    @Inject(Actions) private actions$: Actions,
    private http: HttpClient,
  ) { }

}
