import { ActionReducerMap } from "@ngrx/store";
import { AuthState, authenticationReducer } from "./Authentication/authentication.reducer";
import { LayoutState, layoutReducer } from "./layouts/layout-reducers";
import { UsersState, usersFeatureKey, usersReducer } from "./administration/Users/users.reducer";


export interface RootReducerState {
  layout: LayoutState,
  auth: AuthState;
  [usersFeatureKey]: UsersState,
}

export const rootReducer: ActionReducerMap<RootReducerState> = {
  layout: layoutReducer,
  auth: authenticationReducer,
  [usersFeatureKey]: usersReducer,
}
