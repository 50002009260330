import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { login, loginSuccess, loginFailure, logoutSuccess, register, registerSuccess, registerFailure } from './authentication.actions';
import { Router } from '@angular/router';
import AuthService from 'src/services/AuthService';

@Injectable()
export class AuthenticationEffects {

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(register),
      exhaustMap(({ name, companyName, email, password }) =>
        this.authService.register(name, companyName, email, password).pipe(
          map((user) => registerSuccess({ user: user! })),
          catchError((error) => of(registerFailure({ error })))
        )
      )
    )
  );

  registerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registerSuccess),
      exhaustMap(({ user }) => of(loginSuccess({ user }))),
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      exhaustMap(({ email, password, remember }) => {
        return this.authService.login(email, password, remember).pipe(
          map((user) => loginSuccess({ user })),
          catchError((error) => of(loginFailure({ error })))
        )
      })
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccess),
      tap(({ user }) => {
        localStorage.setItem('auth.user', JSON.stringify(user));
        this.router.navigate(['/']);
      })
    ), { dispatch: false });



  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutSuccess),
      tap(() => {
        localStorage.removeItem('auth.user');
        this.router.navigate(['/auth/login']);
      }),
    ), { dispatch: false },);

  constructor(
    @Inject(Actions) private actions$: Actions,
    private authService: AuthService,
    private router: Router) { }

}
